var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), !_vm.editAll && _vm.$route.params.id ? _c('vx-btn', {
    attrs: {
      "width": "120",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$edit('component-schema', _vm.schema._id);
      }
    }
  }, [_vm._v(" Edit Schema ")]) : _vm._e(), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")]), !_vm.editAll ? _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'next');
      }
    }
  }, [_vm._v(" Save & next ")]) : _vm._e()], 1), !_vm.editAll ? _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.component.setSlug(_vm.component.slug || _vm.component.name);
      }
    },
    model: {
      value: _vm.component.name,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "name", $$v);
      },
      expression: "component.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.component.setSlug(_vm.component.slug);
      }
    },
    model: {
      value: _vm.component.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "slug", $$v);
      },
      expression: "component.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.component.description,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "description", $$v);
      },
      expression: "component.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "date",
      "name": "publishedAt",
      "label": "Publish date"
    },
    model: {
      value: _vm.component.publishedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "publishedAt", $$v);
      },
      expression: "component.publishedAt"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mb-8"
  }), _c('v-card-text', [_c('v-row', _vm._l(_vm.schema.fields, function (field, i) {
    return _c('v-col', {
      key: i,
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [field.type === 'collection' ? [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": field.values,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "multiple": field.multiple,
        "label": field.name,
        "required": !_vm.editAll && field.validation.required && _vm.component._isActive,
        "append-outer-icon": "mdi-plus"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          _vm.$openBlankWindow(_vm.$toAdd('collection', {
            id: field.schemaId
          }));
        }
      },
      model: {
        value: _vm.component.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.component.fields, field.slug, $$v);
        },
        expression: "component.fields[field.slug]"
      }
    })] : _vm.SPECIAL_FIELD_TYPES.includes(field.type) ? _c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": field.values,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "label": field.name,
        "multiple": field.multiple,
        "required": field.validation.required
      },
      model: {
        value: _vm.component.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.component.fields, field.slug, $$v);
        },
        expression: "component.fields[field.slug]"
      }
    }) : field.type === 'file' ? [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": field.name,
        "label": field.name,
        "required": field.validation.required,
        "append-outer-icon": "mdi-folder-multiple-image"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.openFileDialog(field);
        }
      },
      model: {
        value: _vm.component.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.component.fields, field.slug, $$v);
        },
        expression: "component.fields[field.slug]"
      }
    }), _c('vx-img', {
      staticClass: "w-100 w-md-50 border-primary-1 rounded mb-4 mx-auto",
      attrs: {
        "rectangle": "",
        "preview": "",
        "placeholder": "",
        "src": _vm.component.fields[field.slug]
      }
    })] : _c('vx-input', {
      staticClass: "py-0",
      attrs: {
        "type": field.type,
        "name": field.name,
        "label": field.name,
        "items": field.items,
        "multiple": field.multiple,
        "required": field.validation.required,
        "chips": ""
      },
      model: {
        value: _vm.component.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.component.fields, field.slug, $$v);
        },
        expression: "component.fields[field.slug]"
      }
    })], 2);
  }), 1)], 1)], 1), _c('AddFile', {
    on: {
      "resolve": _vm.onResolveFile,
      "reject": function reject($event) {
        _vm.dialog.file = false;
      }
    },
    model: {
      value: _vm.dialog.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "file", $$v);
      },
      expression: "dialog.file"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }