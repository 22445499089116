<template>
  <div>
    <vx-dialog v-model="dialog.file" width="1200" title="Media Library" @reject="$emit('reject')">
      <v-row>
        <v-col cols="12" sm="6">
          <vx-input v-model="search.text" type="text" name="text" label="Search" @change="getFiles()" />
        </v-col>

        <v-col cols="12" sm="6">
          <vx-btn color="primary" @click="$refs.uploader.openModal()"> Upload Media</vx-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="(file, index) in files" :key="index" cols="2" @click="onClick(file)">
          <v-card outlined>
            <vx-img class="rounded cursor-pointer" size="400" square :src="file.url" />
            <v-list-item-title :title="file.name" class="pa-2 text-caption">
              {{ file.name }}
            </v-list-item-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <vx-pagination v-model="pagination" @change="getFiles()" />
        </v-col>
      </v-row>
    </vx-dialog>

    <FileUploader ref="uploader" :options="fileUploaderOptions" @close="getFiles()" />
  </div>
</template>

<script>
import { FileService } from '@tutti/services';
import FileUploader from '@tutti/components/FileUploader';

export default {
  name: 'AddFile',

  components: {
    FileUploader,
  },

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      pagination: {
        page: 1,
        limit: 12,
        count: 0,
      },
      dialog: {
        file: false,
      },
      files: [],
      search: {
        text: '',
      },
      fileUploaderOptions: {
        headers: {
          authorization: this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY),
          partners: false,
          admin: true,
        },
      },
    };
  },

  watch: {
    value(dialog) {
      this.dialog.file = dialog;
    },
  },

  async created() {
    await this.getFiles();
  },

  methods: {
    onClick(file) {
      this.dialog.file = false;
      this.$emit('resolve', file);
    },
    async getFiles() {
      const response = await FileService.getAll({
        page: this.pagination.page,
        limit: this.pagination.limit,
        search: this.search,
        filter: {
          from: 'cms',
        },
      });

      if (response) {
        this.files = response.data.data;
        this.pagination.count = response.data.count;
      }
    },
  },
};
</script>
