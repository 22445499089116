<template>
  <v-form ref="form" lazy-validation>
    <v-card outlined>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn
          v-if="!editAll && $route.params.id"
          width="120"
          outlined
          color="light"
          @click="$edit('component-schema', schema._id)"
        >
          Edit Schema
        </vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
        <vx-btn v-if="!editAll" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'next')">
          Save & next
        </vx-btn>
      </v-card-title>

      <v-card-text v-if="!editAll" class="mt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="component.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="component.setSlug(component.slug || component.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="component.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="component.setSlug(component.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="component.description"
              type="textarea"
              name="description"
              label="Description"
              counter="280"
              max="280"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="component.publishedAt" type="date" name="publishedAt" label="Publish date" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-8" />

      <v-card-text>
        <v-row>
          <v-col v-for="(field, i) in schema.fields" :key="i" cols="12" sm="6" class="py-0">
            <template v-if="field.type === 'collection'">
              <vx-input
                v-model="component.fields[field.slug]"
                type="autocomplete"
                :items="field.values"
                item-text="name"
                item-value="_id"
                :name="field.name"
                :multiple="field.multiple"
                :label="field.name"
                :required="!editAll && field.validation.required && component._isActive"
                append-outer-icon="mdi-plus"
                @click:append-outer="$openBlankWindow($toAdd('collection', { id: field.schemaId }))"
              />
            </template>

            <vx-input
              v-else-if="SPECIAL_FIELD_TYPES.includes(field.type)"
              v-model="component.fields[field.slug]"
              type="autocomplete"
              :items="field.values"
              item-text="name"
              item-value="_id"
              :name="field.name"
              :label="field.name"
              :multiple="field.multiple"
              :required="field.validation.required"
            />

            <template v-else-if="field.type === 'file'">
              <vx-input
                v-model="component.fields[field.slug]"
                type="text"
                :name="field.name"
                :label="field.name"
                :required="field.validation.required"
                append-outer-icon="mdi-folder-multiple-image"
                @click:append-outer="openFileDialog(field)"
              />

              <vx-img
                rectangle
                preview
                placeholder
                class="w-100 w-md-50 border-primary-1 rounded mb-4 mx-auto"
                :src="component.fields[field.slug]"
              />
            </template>

            <vx-input
              v-else
              v-model="component.fields[field.slug]"
              class="py-0"
              :type="field.type"
              :name="field.name"
              :label="field.name"
              :items="field.items"
              :multiple="field.multiple"
              :required="field.validation.required"
              chips
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <AddFile v-model="dialog.file" @resolve="onResolveFile" @reject="dialog.file = false" />
  </v-form>
</template>

<script>
import { Component, ComponentSchema } from '@tutti/models';
import { FIELD_TYPES } from '@tutti/constants';
import { ComponentService, ComponentSchemaService } from '@tutti/services';

import AddFile from '@admin/dialogs/add-file';

export default {
  name: 'LandingPageEdit',

  components: { AddFile },

  props: {
    edit: { type: Boolean, default: false },
    editAll: { type: Boolean, default: false },
  },

  data() {
    return {
      tab: 0,
      SPECIAL_FIELD_TYPES: FIELD_TYPES.filter(field => field.special).map(field => field.value),
      loading: false,
      created: false,
      component: new Component(),
      schema: {},
      field: {},
      dialog: {
        file: false,
      },
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getPage();
    await this.getPageSchema();
    this.created = true;
  },

  methods: {
    openFileDialog(field) {
      this.field = field;
      this.dialog.file = true;
    },

    onResolveFile(file) {
      this.component.fields[this.field.slug] = file.url;
      this.dialog.file = false;
    },

    removeField(array, index) {
      array.splice(index, 1);
      this.key++;
    },

    async getPage() {
      if (this.$route.params.id) {
        const response = await ComponentService.getByIdAdmin(this.$route.params.id);

        if (response) {
          this.component = new Component(response.data);
        }
      }
    },

    async getPageSchema() {
      if (this.$route.query.id || this.component.schemaId) {
        const response = await ComponentSchemaService.getByIdAdmin(this.$route.query.id || this.component.schemaId);

        if (response) {
          this.schema = new ComponentSchema(response.data);
          this.schema.setFields(this.component.fields);

          if (this.editAll) {
            this.$setTitle(`Edit all collections in ${this.schema.name}`);
          } else if (this.edit) {
            this.$setTitle(`Edit ${this.component.name} in ${this.schema.name}`);
          } else {
            this.$setTitle(`Add collection in ${this.schema.name}`);
          }
        }
      } else {
        this.$previous();
      }
    },

    async save(action) {
      this.loading = true;
      this.component.schemaId = this.schema._id;

      const response = this.editAll
        ? ComponentService.editAll(this.component.get())
        : await ComponentService.save(this.component.get());
      if (response) {
        if (action === 'next') {
          this.component = new Component();
          this.$reset();
          this.$setTitle('');
        } else if (action === 'previous') {
          this.$previous();
        } else if (response.data?._id) {
          this.$replace(this.$toEdit('component', response.data?._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
