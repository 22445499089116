var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vx-dialog', {
    attrs: {
      "width": "1200",
      "title": "Media Library"
    },
    on: {
      "reject": function reject($event) {
        return _vm.$emit('reject');
      }
    },
    model: {
      value: _vm.dialog.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "file", $$v);
      },
      expression: "dialog.file"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "text",
      "label": "Search"
    },
    on: {
      "change": function change($event) {
        return _vm.getFiles();
      }
    },
    model: {
      value: _vm.search.text,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "text", $$v);
      },
      expression: "search.text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.uploader.openModal();
      }
    }
  }, [_vm._v(" Upload Media")])], 1)], 1), _c('v-row', [_vm._l(_vm.files, function (file, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "2"
      },
      on: {
        "click": function click($event) {
          return _vm.onClick(file);
        }
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": ""
      }
    }, [_c('vx-img', {
      staticClass: "rounded cursor-pointer",
      attrs: {
        "size": "400",
        "square": "",
        "src": file.url
      }
    }), _c('v-list-item-title', {
      staticClass: "pa-2 text-caption",
      attrs: {
        "title": file.name
      }
    }, [_vm._v(" " + _vm._s(file.name) + " ")])], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-pagination', {
    on: {
      "change": function change($event) {
        return _vm.getFiles();
      }
    },
    model: {
      value: _vm.pagination,
      callback: function callback($$v) {
        _vm.pagination = $$v;
      },
      expression: "pagination"
    }
  })], 1)], 2)], 1), _c('FileUploader', {
    ref: "uploader",
    attrs: {
      "options": _vm.fileUploaderOptions
    },
    on: {
      "close": function close($event) {
        return _vm.getFiles();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }